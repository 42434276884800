



























import { Component, Prop, Vue } from 'vue-property-decorator';
import CreateCoupon from '@rs-911/components/modal/CreateCoupon.vue';
import UpdateCoupon from '@rs-911/components/modal/UpdateCoupon.vue';
import { Coupon }  from '@shared/models/coupon';
import { AgGridVue } from 'ag-grid-vue';
import { RowClickedEvent } from 'ag-grid-community';

@Component({
  components: {
    CreateCoupon,
    UpdateCoupon,
    AgGridVue
  }
})
export default class CouponAdmin extends Vue {
  coupons: Coupon[] = [];
  selectedCoupon: Coupon | null = null;

  async created() {
    this.coupons = (await this.$store.dispatch('coupon/getAll')).data;
  }

  defaultColDefs = {
    resizable: true,
    flex: 1,
    sortable: true,
    filter: true,
    // editable: true,
    cellStyle: {textAlign: 'left'}
  };
  
  onRowClicked(event: RowClickedEvent) {
    this.selectedCoupon = event.data;
    this.$bvModal.show('modal-update-coupon');
  }

  newCoupon(coupon: Coupon) {
    this.coupons.push(coupon);
    this.$store.dispatch('notify', {title: 'New Coupon', body: `Coupon created with code: "${coupon.code}"`, variant: 'success'});
  }

  updateCoupon(coupon: Coupon) {
    console.log(coupon);
    const index = this.coupons.findIndex(x => x.id === coupon.id);
    Vue.set(this.coupons, index, coupon);
    this.$store.dispatch('notify', {title: 'Coupon Update', body: `Coupon "${coupon.code}" updated`, variant: 'info'});
  }

  deleteCoupon(coupon: Coupon) {
    const index = this.coupons.findIndex(x => x.id === coupon.id);
    this.coupons.splice(index, 1);
    this.$store.dispatch('notify', {title: 'Coupon Delete', body: `Coupon "${coupon.code}" deleted`, variant: 'info'});
  }

  columnDefs= [
    // { headerName: 'Id', field: 'id' },
    { headerName: 'Code', field: 'code' },
    { headerName: 'Feature Name', field: 'featureName'},
    { headerName: 'Description', field: 'description' },
    { headerName: 'Use Count', field: 'useCount' },
    { headerName: 'Max Uses', field: 'maxUses' },
    { headerName: 'Percentage Discount', field: 'percentageDiscount' },
    { headerName: 'Fixed Discount', field: 'fixedDiscount' },
    { headerName: 'IsActive', field: 'active' },
  ];
}
