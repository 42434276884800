

























































































































import { Component, Vue } from 'vue-property-decorator';
import { required, numeric, minValue, maxValue } from 'vuelidate/lib/validators';
Component.registerHooks(['validations']);

@Component
export default class CreateCoupon extends Vue {
  $v: any;
  showOverlay = false;
  form = {
    featureName: null,
    description: null,
    maxUses: null,
    percentageDiscount: null,
    fixedDiscount: null,
    active: false
  }

  validations() {
    return {
      form: {
        featureName: {
          required
        },
        description: {
          required
        },
        maxUses: {
          required,
          numeric,
          minValue: minValue(0)
        },
        fixedDiscount: {
          numeric
        },
        active: { },
        percentageDiscount: {
          numeric,
          minValue: minValue(1),
          maxValue: maxValue(100)
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      featureName: null,
      description: null,
      maxUses: null,
      percentageDiscount: null,
      fixedDiscount: null,
      active: false
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }

    const newCoupon = {
      featureName: this.form.featureName,
      description: this.form.description,
      maxUses: parseInt(this.form.maxUses || ''),
      active: this.form.active
    };
    if (this.form.percentageDiscount) {
      (newCoupon as any).percentageDiscount = parseInt(this.form.percentageDiscount || '');
    }

    if (this.form.fixedDiscount) {
      (newCoupon as any).fixedDiscount = parseInt(this.form.fixedDiscount || '');
    }

    this.showOverlay = true;
    this.$store.dispatch('coupon/create', newCoupon)
      .then(res => {
        this.$emit('newCoupon', res);
        this.clearForm();
        this.showOverlay = false;
        this.$bvModal.hide('modal-create-coupon');
      },
      error => {
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Error occured while trying to create coupon', body: `${error?.data?.message}`, variant: 'danger'});
      });
  }

  clearForm() {
    this.resetForm();
  }
}
